<template>
  <div>

    <h1>Violin Lessons in Honolulu, Hawaii</h1>

    <img class="rphoto" alt="The Suzuki Method" src="../assets/04.jpg">

    <p><b>Violin Artistry Studio</b> teaches children of all ages and levels, incorporating the philosophy of the Suzuki Method™. Private lessons are tailored to each student's needs, interests and learning capabilities. If you are a parent of a young child, visit the <a href="https://violinartistry.com/suzuki-approach">Suzuki Approach</a> Page for more information.</p>
    <p>If you would like to take lessons with Dr. Nikki Routman Ebisu, please contact us and provide the student’s name, age and availability.</p>
    <p>Parents of younger students may schedule a consultation to address any questions, and the teacher will observe the child’s readiness to begin instruction.  If your desired time is not available, you may request to be added to our waiting list.</p>
    <p>Lessons are available in English and Japanese.</p>
    <br>
    <h2>Online & in-person lessons due to Covid-19</h2>
    <p>Due to the Covid-19 pandemic, lessons are being offered both in-person and online. It is recommended that beginners receive in-person lessons, due to the complexity of the instrument.</p>
    <p>Safety measures are being taken to sanitize the studio and ensure proper social distancing. Hand-sanitizers are readily available.</p>
    <br>

    <h1>Dr. Nikki Routman Ebisu</h1>

    <img class="lphoto" alt="Dr. Nikki Routman Ebisu" src="../assets/nikki_ebisu-267r_BW.jpg">

    <p>Dr. Nikki (Routman) Ebisu enjoys an esteemed career as a violinist, teacher and clinician, celebrated for her dedication to music and education. Her passion for music has led to a global career, featuring collaborative chamber music and orchestral performances across Europe, the USA, and Asia. Recognized as the <a href="https://www.hmta.org/news/2022/hmta-teacher-of-the-year-nikki-routman-ebisu" target="_blank" rel="noopener">'2022 Teacher of the Year'</a> by the Hawaii Music Teachers Association, Dr. Ebisu is a sought-after Japanese bilingual violin instructor and one of the few remaining protégés of Dr. Shinichi Suzuki, the visionary founder of the Suzuki Method.
    </p>

    <p>As the owner of Violin Artistry Studio in Honolulu, Dr. Ebisu specializes in providing violin education for students aged 3 and up, guiding students from the beginning through advanced levels.  Dr. Ebisu is a faculty member at 'Iolani school as the Violin/Viola Instructor for Beginning Orchestra. Past positions include Part-Time Lecturer in Violin at the University of Hawai'i at Mānoa, Assistant and Adjunct Instructor at the University of Colorado at Boulder, and faculty at Conservatory Music in the Mountains Summer Festival in Colorado. Each summer, she joins the Violin Faculty at the Interharmony Music Festival in Acqui Terme, Italy.</p>

    <p>As a performer, she has graced the stages of prominent orchestras including the Hawai'i Symphony Orchestra, Honolulu Symphony, Maui Pops Orchestra, World Symphony Orchestra (South Korea), Colorado Springs Philharmonic, Austin Symphony and Charleston Symphony, among others.</p>

    <p>Dr. Ebisu's students consistently achieve success in national and international competitions, excelling within programs such as the Hawaii Youth Symphony and Meadowmount Summer Festival. Many have been featured in exclusive masterclasses with renowned soloists such as Sarah Chang, Ray Chen, Augustin Hadelich, Midori, Ryu Goto, Alexi Kenney, Robert McDuffie and others. From  captivating performances at Carnegie Hall to being featured on NPR From the Top, her students continue to inspire with their accomplishments.</p>

    <p>Dr. Ebisu holds Bachelor of Arts degree in Music and Pre-Medicine from the University of Texas at Austin, along with Master's and Doctorate degrees in Violin Performance from the University of Colorado at Boulder. Her primary instructors include Dr. Shinichi Suzuki, Sumiko Edo, John Arnold, Dr. Eugene Gratovich, and Oswald Lehnert.</p>
    
    <p>A committed advocate for music education, Dr. Ebisu shares her passion through recitals, conferences and workshops. During her tenure as President of the Hawaii Music Teachers Association, the organization earned national distinction as the '2022 MTNA National State Chapter Affiliate of the Year.'</p>


  </div>
</template>

<script>
export default {
  name: 'home',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/settings.scss";


</style>
